import { Injectable } from '@angular/core';
import { Book, RootJSONObject } from '../../model/book'
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from '../language/language.service';

const baseUrl = 'assets';

@Injectable({
  providedIn: 'root'
})
export class BooksService {

  books: Book[];
  constructor(private http: HttpClient,
    private languageService: LanguageService) {

  }

  hasBooks(): boolean {
    return this.books != undefined && this.books.length > 0;
  }
  get(route: string): Observable<Book[]> {
    const url = baseUrl + route;
    var service = this;
    var obs = new Observable<Book[]>(function (observer) {
      console.log('GET ' + url);
      service.http.get(url, { responseType: 'arraybuffer', }).subscribe(function (response) {
        var textDecoder = new TextDecoder('utf8');
        let resp: RootJSONObject = JSON.parse(textDecoder.decode(response));
        service.books = resp.imnuri;
        observer.next(resp.imnuri);
      });
      // service.http.get<RootJSONObject>(url, { responseType: 'json' }).subscribe(res => {
      //   service.books = res.imnuri;
      //   observer.next(res.imnuri);
      // });
    });
    return obs;
  }

  getBookByNumber(bookNumber: number): Observable<Book> {
    let self = this;
    var obs = new Observable<Book>(function (observer) {
      if (self.hasBooks()) {
        observer.next(self.books.filter(b => b.numar == bookNumber)[0]);
      }
      else {
        self.loadBooks().subscribe(() => {
          if (self.hasBooks()) {
            var b = self.books.filter(b => b.numar == bookNumber)[0];
            observer.next(b);
          } else {
            observer.next(undefined);
          }
        });
      }
    });
    return obs;
  }
  loadBooks(): Observable<Book[]> {
    return this.get('/' + this.languageService.currentLanguage + '.json');
  }
}
