import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SearchComponent } from './search/search.component';
import { DetailsComponent } from './details/details.component';
import { HomeComponent } from './home/home.component';
import { UploadComponent } from './upload/upload.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'songs', component: SearchComponent },
  { path: 'upload', component: UploadComponent },
  { path: 'details', component: DetailsComponent,  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
