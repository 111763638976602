<!-- <mat-toolbar color="primary">
  
  <div class="details-title"><span>{{book?.titlu}}</span></div>
  <button mat-icon-button class="right-icon play-icon" aria-label="Example icon-button with heart icon"
    *ngIf="book.mp3">
    <img src="../../assets/mp3_white.png">
  </button>
</mat-toolbar> -->
<div style="display: flex; flex-direction: column;padding:20px; gap:10px">
  <button mat-icon-button class="back-icon" aria-label="Back button icon" (click)="goBack()">
    <mat-icon>arrow_back_ios</mat-icon> Înapoi
  </button>

  <!--
<div class="d-flex mb-3">  
  <img class="p-2" style="height: 35px;" src="/assets/png.png" />
  <img class="p-2" style="height: 35px;" src="/assets/mp3.png" />
</div>
-->
  <!-- <audio [src]="mp3Path" *ngIf="mp3View"></audio> -->

  <p class="versuri" *ngFor="let strofa of book?.strofe" [innerHTML]="strofa | replaceNewLine"></p>

</div><!-- 
<div *ngIf="standardView" class="text-container">
  <mat-tab-group headerPosition="bottom" class="detail-tab-group">
    <mat-tab>
      <ng-template mat-tab-label class="tab-label">
        Versuri
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="book.sheet">
      <ng-template mat-tab-label class="tab-label">
        Partitura
      </ng-template>
      <img [src]="pngPath" />
    </mat-tab>
  </mat-tab-group>

</div> -->