import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { BooksService } from '../services/books/books.service';
import { LanguageService } from '../services/language/language.service';
import { Subscription } from 'rxjs';
import { Book } from '../model/book';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  searchForm: FormGroup;
  offline: boolean;
  searchTerm: string = '';
  displayedColumns: string[] = ['title', 'author', 'publication', 'details'];
  books: Book[];
  lastKeyUp: number = undefined;
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private bookService: BooksService,
    public langService: LanguageService) {
    this.langService.events.on(langService.languageChanged, (l) => {
      console.log(l);
      this.loadBooks();
    });
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required],
    });
    this.loadBooks();
  }
  changeLanguage(lang: string) {
    this.langService.setLanguage(lang);
  }
  ngOnDestroy() {
  }
  async loadBooks() {
    await this.bookService.loadBooks().subscribe(result => {
      this.books = result;
    });
  }
  viewDetails(book: Book) {
    this.router.navigate(['details'], {
      queryParams: {
        id: book.numar
      }
    });
  }
  change(txt: string) {
    console.log("Change:" + txt);
  }

  onSearch(txt?: string) {
    this.lastKeyUp = Date.now();
    setTimeout(() => {
      console.log(Date.now() - this.lastKeyUp);
      if (Date.now() - this.lastKeyUp > 260) {
        this.filterByText(txt);
      }
    }, 300);
  }
  filterByText(txt: string) {
    txt = txt.toLowerCase();
    let filteredBooks: Book[] = undefined;
    if (txt == "<><") {
      filteredBooks = this.bookService.books.filter(b => {
        let shortestLines = 0;
        b.strofe.forEach(str => {
          str.split("\r\n").forEach(line => {
            if (line.length < 10) {
              shortestLines++;
            }
          });
        });
        b.shortestLines = shortestLines;
        return shortestLines > 0;
      }).sort((b0, b1) => b0.shortestLines - b1.shortestLines);
    } else {
      filteredBooks = this.bookService.books.filter(b => {
        let hasTitle = b.titlu.toLowerCase().indexOf(txt) != -1;
        let hasNumber = b.numar.toString().indexOf(txt) != -1;
        b.searchData = undefined;
        let hasStrofe = b.strofe.filter(str => {
          //console.log(str);
          let strofeContains = str.toLowerCase().indexOf(txt) != -1 || str.toLowerCase().split(",").join("").indexOf(txt) != -1;
          str.split("\r\n").filter(line => {
            let lineContains = line.toLowerCase().indexOf(txt) != -1
            if (b.searchData == undefined && lineContains) {
              b.searchData = line;
            }
            return lineContains;
          });
          return strofeContains;
        }).length != 0;
        return hasTitle || hasNumber || hasStrofe
      });
    }
    console.log(filteredBooks);
    this.books = filteredBooks;
    //this.router.navigate(['search'], { queryParams: { query: this.searchForm.get('search').value } });
  }
  login() {
    // this.oktaAuth.loginRedirect();
  }

  logout() {
    //this.oktaAuth.logout('/');
  }

}
