<section>
    <img src="assets/AmaliaRobert.jpg" alt="Amalia and Robert">
    <div class="box">
        <a (click)="goTo('songs')" class="item item-left">
            <img src="assets/songs.svg" alt="">
            <p>Choose Songs</p>
        </a>
        <a (click)="goTo('upload')" class="item item-right">
            <img src="assets/photos.svg" alt="">
            <p>Upload Photos</p>
        </a>
    </div>
</section>