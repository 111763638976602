import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | repaceNewLine:exponent
 * Example:
 *   {{ 2 | repaceNewLine:10 }}
 *   formats to: 1024
*/
@Pipe({ name: 'replaceNewLine' })
export class ReplaceNewLinePipe implements PipeTransform {
    transform(value: string): string {
        let text: string = "<br/>";
        let res = value.split("\r\n").join(text);
        console.log(res);
        return res;
    }
}