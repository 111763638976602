import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BooksService } from '../services/books/books.service';
import { Book } from '../model/book';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  private subscription: Subscription;

  book: Book;
  pngPath: string;
  mp3Path: string;
  mp3View: boolean;
  pngView: boolean;
  standardView: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bookService: BooksService) {
    this.subscription = this.route.queryParams.subscribe(params => {
      this.updateDetails(params.id);
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  goBack() {
    console.log("going back");
    window.history.back();
    console.log("after navigateByUrl");
  }

  padLeft(val: number, padChar: string, length: number) {
    let res = val.toString();
    while (res.length < length) {
      res = padChar + res;
    }
    return res;
  }
  updateDetails(bookNumber) {
    console.log("updateDetails:" + bookNumber);
    this.bookService.getBookByNumber(bookNumber).subscribe(res => {
      this.book = res;
      if (this.book.sheet) {
        this.pngPath = "/assets/png/ro/" + this.padLeft(this.book.numar, '0', 3) + ".png";
      }
      else {
        this.pngPath = "";
      }
      this.standardView = true;
      this.mp3View = false;
      this.pngView = false;
      console.log(this.pngPath);
    });
  }
}
