<!-- <mat-toolbar color="primary" class="expanded-toolbar">
  <div *ngIf="offline">offline</div>
  <form [formGroup]="searchForm" (ngSubmit)="onSearch()" >
    <div class="input-group">
      <input class="input-group-field" type="search" value="" placeholder="Search" formControlName="search">
      <div class="input-group-button"><button mat-flat-button color="accent">
          <mat-icon>search</mat-icon>
        </button></div>
    </div>
  </form>
</mat-toolbar> -->
<mat-action-list>
  <mat-list-item  href="details?id={{book.numar}}" *ngFor="let book of this.books" (click)="viewDetails(book)">
    {{book.numar | number:'3.0'}}
    {{book.titlu}}
  </mat-list-item>
</mat-action-list>