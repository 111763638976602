<section>
    <div class="navigation">
        <!-- <a (click)="goHome()"><img src="assets/ep_back.svg" alt="Back"> &nbsp;Back</a> -->
        <!-- <h1>Alege o poza:</h1> -->
    </div>
    <form class="add-image" #formUpload action="http://wedding.appboxstudios.com/upload.php" method="POST">
        <div [ngClass]="'file-drop-zone ' + (hasPhoto?' background-added height-added':'')" id="fileDropZone"
            [ngStyle]="{ 'background': (hasPhoto && backgroundImage != 'none' ) ? 'url(' + backgroundImage + ')' : 'none'}"
            (drop)="handleDrop($event)" (dragover)="onDragOver($event)" (click)="fileUpload.click()">
            <img [ngStyle]="{'display':hasPhoto?'block':'none','width':'100vw','height':'fit-content'}"
                [src]="backgroundImage" />
            <img src="assets/photos.svg" class="choose-photo" alt="">
            <p>Alege o poză</p>
            <input type="file" id="fileInput" class="file-input" accept=".jpg, .png, .pdf" #fileUpload name="uploadedfile"
                (change)="handleFiles(fileUpload.files)"> 
        </div>
        <div class="buttons" [ngStyle]="{display:hasPhoto?'block':'none'}">
            <button id="replaceButton" class="replace-button" (click)="fileUpload.click()"><img
                    src="assets/ci_arrow-reload-02.svg" alt="replace">Inlocuiește Poza</button>
            <button id="deleteButton" class="delete-button" (click)="handleDelete()"> <img src="assets/mi_delete.svg"
                    alt="">Șterge</button>
        </div>
        <button [disabled]="!hasPhoto" id="submitButton" type="submit" class="submit-button" style="position:fixed;bottom:0px;"
            (click)="uploadPicture()">Încarcă poza</button>
    </form>
</section>
<script>
    



</script>