import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";

import { BooksService } from './services/books/books.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'RobertAmaliaWedding';
  
  isAuthenticated: boolean;
  onNetworkStatusChange() {
    let offline = !navigator.onLine;
    console.log('offline ' + offline);
  }

  constructor(private formBuilder: FormBuilder,
    private router: Router) {
  }

  ngOnInit() {
    window.addEventListener('online', this.onNetworkStatusChange.bind(this));
    window.addEventListener('offline', this.onNetworkStatusChange.bind(this));
    //this.oktaAuth.isAuthenticated().then((auth) => {this.isAuthenticated = auth});
  }

}
