import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  @ViewChild('formUpload') formUpload;
  @ViewChild('fileUpload') fileUpload;


  file: File = null; // Variable to store file
  hasPhoto: boolean;
  backgroundImage: string = "";
  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
    } else {
      // List cameras and microphones.
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
          });
        })
        .catch((err) => {
          console.log(`${err.name}: ${err.message}`);
        });
    }
  }

  handleDrop(event) {
    event.preventDefault();
    console.log(event);
    const files = event.dataTransfer.files;
    this.handleFiles(files);
  }
  goHome() {
    this.router.navigate([''])
  }
  handleFiles(files) {
    if (files.length > 0) {
      const file = files[0];

      if (file.type.startsWith('image/')) {
        this.file = file;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.backgroundImage = event.target.result.toString();
          // console.log(this.backgroundImage)
          this.hasPhoto = true;//fileDropZone.classList.add('background-added', 'height-added');
          // document.querySelector('.buttons').style.display = 'flex';
          // submitButton.disabled = false;
          // this.fileUpload.value = event.target
        };
        reader.readAsDataURL(file);
      }
    } else {
      this.file = null;
      this.hasPhoto = false;
      this.backgroundImage = 'none';
      // fileDropZone.classList.remove('background-added', 'height-added');
      // document.querySelector('.buttons').style.display = 'none';
      // submitButton.disabled = true;
    }
  }
  handleDelete() {
    this.file = null;
    this.hasPhoto = false;
    this.backgroundImage = 'none';
    // fileInput.value = ''; // Reset the file input

    // Add background gradient after deleting
    // fileDropZone.style.background = 'linear-gradient(180deg, #F6EDEF 0%, #FFF 100%)';
  }
  onDragOver(event) {

    event.stopPropagation();
    event.preventDefault();
  }
  uploadPicture() {
    // this.formUpload.submit();
    console.log(this.formUpload);
    // this.formUpload.nativeElement.submit();//.ngSubmit.emit();
    const formData = new FormData();

    // Store form name as "file" with file data
    formData.append("file", this.file, this.file.name);
    let device_id = localStorage.getItem('device_id');
    // Make http post request over api
    // with formData as req
    var respp = this.http.post('https://wedding.appboxstudios.com/upload.php?local_id=' + device_id, formData).subscribe((ev: any) => {
      console.log("RESPONSE:", ev.local_id);
      localStorage.setItem("device_id", ev.local_id);
    });
    console.log(respp);
  }
}
