import { Injectable } from '@angular/core';
import { Book } from '../../model/book'
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as EventEmitter from 'events';
import { CookieService } from 'ngx-cookie-service';

const baseUrl = 'assets';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  currentLanguage: string;
  books: Book[];
  events: EventEmitter = new EventEmitter();
  languageChanged: string = "languageChanged";
  cookieLangKey: string = "currentLanguage";

  constructor(private http: HttpClient,
    private cookieService: CookieService) {
    let storedLanguage = this.cookieService.get(this.cookieLangKey);
    console.log("StoredLanguage:" + storedLanguage.length)
    if (storedLanguage == undefined || storedLanguage.length == 0) {
      this.setLanguage("ro");
    } else {
      this.currentLanguage = storedLanguage;
    }
  }
  hasBooks(): boolean {
    return this.books != undefined && this.books.length > 0;
  }
  setLanguage(lang: string) {
    this.currentLanguage = lang;
    this.cookieService.set(this.cookieLangKey, lang);
    this.events.emit(this.languageChanged, this.currentLanguage);
  }
  get(route: string): Observable<Book[]> {
    const url = baseUrl + route;
    var service = this;
    var obs = new Observable<Book[]>(function (observer) {
      console.log('GET ' + url);
      service.http.get<Book[]>(url, { responseType: 'json' }).subscribe(res => {
        service.books = res;
        observer.next(res);
      });
    });
    return obs;
  }

  getBookByNumber(bookNumber: any): Observable<Book> {
    let self = this;
    var obs = new Observable<Book>(function (observer) {
      if (self.hasBooks()) {
        observer.next(self.books.filter(b => b.numar == bookNumber)[0]);
      }
      else {
        self.loadBooks().subscribe(() => {
          if (self.hasBooks()) {
            var b = self.books.filter(b => b.numar == bookNumber)[0];
            observer.next(b);
          } else {
            observer.next(undefined);
          }
        });
      }
    });
    return obs;
  }
  loadBooks(): Observable<Book[]> {
    return this.get('/books.json');
  }
}
